<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @get-users="getUsers"
      :role-options="roleOptions"
    />

    <user-list-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      @get-users="getUsers"
      :role-options="roleOptions"
      :edit-user-data="editUserData"
    />
    
    <!-- Filters -->
<!--     <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--               <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">添加用户</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <div class="table-responsive text-nowrap">
      <b-table
        :items="users"
        :fields="fields"
      >
      <template #cell(actions)="data">
        <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
            @click="isEditUserSidebarActive = true;
            editUser(data.item.user_id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item 
              @click="delUser(data.item.user_id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除用户</span>
            </b-dropdown-item>
          </b-dropdown>
      </template>
      </b-table>
      </div>


    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import UserListEdit from './UserListEdit.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    UserListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data(){
    return {
        editUserData: {},
        users:[],
        fields: [
          {
            key: 'user_name',
            label:"姓名",
          },
          {
            key: 'user_email',
            label:"邮箱",
            sortable: false
          },
          {
            key:"user_wechat_id",
            label:"企业微信ID",
          },
          {
            key:"user_role",
            label:"权限",
          },
          {
            key:"actions",
            label:"操作",
          },
        ],
    }
  },
  methods: {
  getUsers: function(){
    this.users = []
    this.$axios.get('/manage/users/get_users').then((response) => {
        response.data.data.users.forEach(i => {
          this.users.push(i)
        });
    })
  },
  delUser(userId){
    this.$swal({
        title: '删除用户',
        text: "删除后无法撤销，你确定要删除该用户吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result =>{
        if(result.value){
          this.$axios.get('/manage/users/del_user/'+userId, {
        }).then((response) => {
          if (response.data.status == "ok"){
            this.getUsers()
          }
    })
        }
        
      })

      

  },
  editUser(userId){
    this.users.forEach(i => {
      if (i.user_id == userId){
        this.editUserData = i
      }
    });
  }
},
  created() {
      this.getUsers()
  },
  setup() {
   
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const isEditUserSidebarActive = ref(false)
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Client', value: 'client' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      isEditUserSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
